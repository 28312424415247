// import React, { Component, useState, useEffect } from "react";
// import CanvasJSReact from "@canvasjs/react-charts";
// import { Base_url, getSickness } from "../../Config/api/api";
//
// const CanvasJSChart = CanvasJSReact.CanvasJSChart;
// class Chart extends Component {
//   render() {
//     const { sickness } = this.props;
//     console.log(sickness);
//     const dataPoints = [
//       { label: "BCC", y: parseInt(sickness?.BCC) , color: "#4F81BC" },
//       { label: "SCC", y: parseInt(sickness?.SCC) , color: "#4F81BC" },
//       { label: "Mel", y: parseInt(sickness?.Mel) , color: "#4F81BC"},
//       { label: "DF", y: parseInt(sickness?.DF) , color: "#4F81BC"},
//       { label: "NV", y: parseInt(sickness?.NV)  , color: "#4F81BC"},
//       { label: "VASC", y: parseInt(sickness?.VASC) , color: "#4F81BC"},
//       { label: "BKL", y: parseInt(sickness?.BKL)  , color: "#4F81BC"},
//       { label: "Malignant", y: parseInt(sickness?.Malignant)  , color: "#4F81BC"},
//       { label: "Benign", y: parseInt(sickness?.Benign)  , color: "#4F81BC"},
//     ];
//
//     const options = {
//       title: {
//       },
//       data: [
//         {
//           type: "column",
//           dataPoints: dataPoints,
//         },
//       ],
//     };
//
//     return (
//       <div>
//         <CanvasJSChart options={options} />
//       </div>
//     );
//   }
// }
//
// export default Chart;
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,  
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);
const BarChart = ({sickness}) => {
  const data = {
    labels: ['BCC', 'SCC', 'Mel', 'DF', 'NV', 'VASC','BKL'],
    datasets: [
      {
        data: [sickness.BCC, sickness.SCC, sickness.Mel, sickness.DF, sickness.NV,sickness.VASC, sickness.BKL],
        backgroundColor: '#4F81BC',
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: false, // Disable the title for the whole chart
      },
      legend: {
        display: false, // Disable the legend
      },

      tooltip: {
        enabled: false, // Disable the tooltip
      },
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: (value) => Math.round(value) + "%", // Display the value as is
        color: 'black',
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Disable grid lines on the x-axis
        },

        ticks: {
          font: {
            weight: 'bold', // Make the x-axis labels bold
          }, // Disable y-axis labels (ruler)
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        grid: {
          display: false, // Disable grid lines on the x-axis
        },
        ticks: {
          display: false, // Disable y-axis labels (ruler)
        },
      },
    },
    animation: {
      duration: 0, // Disable chart animation
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
