import "./App.css";
import React, { useCallback, useContext, useState } from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import AuthContext from "./context/authContext";
export default function App() {
  const router = useRoutes(routes);
  const [doctorIsLoggedIn, setDoctorIsLoggedIn] = useState(null);
  const [adminIsLoggedIn, setAdminIsLoggedIn] = useState(null);
  const [adminToken, setAdminToken] = useState(false);
  const [doctorToken, setDoctorToken] = useState(false);
  const [doctorInfos, setDoctorInfos] = useState(null);
  const [adminInfos, setAdminInfos] = useState(null);
  const adminLogin =(userInfos, token) => {
    setAdminToken(token);
    setAdminIsLoggedIn(true);
    setAdminInfos(userInfos);
    localStorage.setItem("admin", JSON.stringify({ token }));
  }
  const adminLogout = useCallback(() => {
    setAdminToken(null);
    setAdminInfos({});
    localStorage.removeItem("admin");
  }, []);
  const doctorLogin =(userInfos, token) => {
    setDoctorToken(token);
    setDoctorIsLoggedIn(true);
    setDoctorInfos(userInfos);
    localStorage.setItem("doctor", JSON.stringify({ token }));
  }
  const doctorLogout = useCallback(() => {
    setDoctorToken(null);
    setDoctorInfos({});
    localStorage.removeItem("doctor");
  }, []);
  

  const myProf=()=>{

  }
  return (
    <AuthContext.Provider
      value={{
        doctorIsLoggedIn,
        adminIsLoggedIn,
        doctorToken,
        adminToken,
        doctorInfos,
        adminInfos,
        doctorLogin,
        adminLogin,
        doctorLogout,
        adminLogout
      }}
    >
      {router}
    </AuthContext.Provider>
  );
}
