import React, { useEffect, useState } from "react";
import "./Pagination.css";
import { Link, useParams } from "react-router-dom";
export default function Pagination({
  items,
  total,
  itemsCount,
  pathname,
  setShownUsers,
}) {
  const [pageCount, setPageCount] = useState(null);
  const { page } = useParams();

  useEffect(() => {

    setShownUsers(items);
    let pagesNumber = Math.ceil(total / itemsCount);
    setPageCount(pagesNumber);
  }, [items]);

  return (
    <div className="users-pagination">
      <ul className="users__pagination-list">
        {Array(pageCount)
          .fill(0)
          .map((item, index) => (
            <li className="users__pagination-item">
              {index + 1 === Number(page) ? (
                <Link
                  to={`${pathname}/${index + 1}`}   
                  className="users__pagination-link users__pagination-link--active rounded-full"
                >
                  {index + 1}
                </Link>
              ) : (
                <Link
                  to={`${pathname}/${index + 1}`}
                  className="users__pagination-link rounded-full"
                >
                  {index + 1}
                </Link>
              )}
            </li>
          ))}

        {/* <li className="users__pagination-item">
          <a
            href="#"
            className="users__pagination-link users__pagination-link--active"
          >
            3
          </a>
        </li> */}
      </ul>
    </div>
  );
}
