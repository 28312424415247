import React, {useRef} from 'react';

const ChatWindow = ({chatMessages, addMessage}) => {
    const inputRef = useRef(null);

    return (
        <div
            className={'absolute bottom-24 right-0 min-w-[25vw] min-h-[50vh]  bg-white border border-gray-300 rounded-lg shadow-lg'}>
            {/* Chat Header */}
            <div className="bg-blue-500 text-white p-4 rounded-t-lg"
                 style={{position: "absolute", top: 0, width: '100%'}}
            >
                <h2 className="text-xl font-semibold text-left">Chat with patient</h2>

            </div>

            {/* Chat Messages */}
            {/* Chat Messages */}

            <div className="flex flex-col flex-grow p-4 space-y-4 overflow-y-auto"
                style={{maxHeight: 'calc(80vh - 160px', marginTop: '10%', marginBottom: '10%'}}>
                {chatMessages.length === 0 ? (
                    <div className="flex flex-grow justify-center  text-gray-500 mt-[30%]">
                        <p>No messages yet. Start the conversation!</p>
                    </div>
                ) : (

                    chatMessages.map((chat, index) => (
                        <div key={index}
                             className={`flex w-full mb-2 ${chat.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
                            <div className={`flex-1 ${chat.sender === 'user' ? 'hidden' : 'block'}`}/>

                            <div
                                className={`flex-1 max-w-xl ${chat.sender === 'user' ? 'bg-blue-100 text-right' : 'bg-gray-100 text-left'} p-4 rounded-lg`}>
                                <span className="font-bold text-black">{chat.content}</span>
                                <span className="text-xs text-gray-500 block mt-1">{chat.insertDate}</span>
                            </div>

                            <div className={`flex-1 ${chat.sender === 'user' ? 'block' : 'hidden'}`}/>
                        </div>
                    ))
                )}
            </div>
            {/* Input Section */}
            <div
                style={{position: "absolute", bottom: 0, width: '100%'}}
                className="flex items-center p-2 border-t border-gray-300 bg-white ">
                <input
                    type="text"
                    ref={inputRef}
                    placeholder="Type your message..."
                    className="flex-grow p-2 border border-gray-300 rounded-l-lg focus:outline-none h-[40px]"
                />
                <button
                    onClick={() => {
                        if (inputRef.current.value.toString() !== "") {
                            addMessage(inputRef.current.value);
                            inputRef.current.value = "";
                        }
                    }}
                    className="bg-blue-500 text-white rounded-r-lg h-[40px] hover:bg-blue-700 transition duration-300"
                >
                    <i className="fa fa-paper-plane"></i>
                </button>
            </div>
        </div>
    );
};

export default ChatWindow;
