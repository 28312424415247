import React, { useState } from "react";
import {sd} from "../../Assets/photos/user_ic.png"
import {Navigate, useNavigate } from "react-router-dom";
export default function AdminUserBox(user) {
  const navigate =useNavigate()
  const [isImgShow, setIsImgShow] = useState(false);

  return (
    <tr  
   className= " divide-x devide-solid hover:cursor-pointer w-full items-center grid lg:grid-cols-9 grid-cols-10  hover:bg-slate-100   gap-3 px-6 py-4 font-normal text-gray-800 text-left lg:text-lg text-sm align-middle ">
      <td className=" border-neutral-500 col-span-2 border-x-2   py-4">
      {user.doctorName=="" ? "-" :user.doctorName}
      </td>
      <td className=" border-neutral-500 col-span-2 py-4">
      {user.gender=="" ? "-" :user.gender}
      </td>
      <td className=" border-neutral-500 col-span-2 py-4">
      {user.lastname=="" ? "-" :user.lastname}
      </td>
      <td className=" border-neutral-500 col-span-2  py-4">
        {user.firstname=="" ? "-" :user.firstname}
      </td>
      <td id="image" className=" border-neutral-500 float-start  lg:col-span-1 col-span-2   py-4 left-0" >
        <img
      
          src={`${user.image != "" ? user.image :"https://th.bing.com/th/id/OIP.UY0H6jNLhhjKymJWT6HsPwHaHa?rs=1&pid=ImgDetMain"}`}
          alt="patient's image"
          className="  rounded-full object-cover object-center  h-16 w-16 left-0"
        />
      </td>
    </tr>
  );
}
