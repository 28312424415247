import React, {useContext, useEffect, useRef, useState} from "react";
import { useForm } from "../../hooks/useForm";
import swal from "sweetalert";
import Input from "../../Components/Form/Input";
import { Navigate, json, useNavigate } from "react-router-dom";
import AuthContext from "../../context/authContext";
import { Base_url, uploadPhoto } from "../../Config/api/api";
import "./EditProfile.css";
import Logo from "../../Components/Logo/Logo";
import {
  requiredValidator,
  minValidator,
  maxValidator,
  emailValidator,
} from "../../validators/rules";
import Button from "../../Components/Form/Button";
export default function BasicTextFields() {
  const navigate = useNavigate();
  const [doctorProfile, setDoctorProfile] = useState([]);
  const authContext = useContext(AuthContext);
  const profileNameRef = useRef(null);

  useEffect(() => {
    let p = JSON.parse(localStorage.getItem("doctorProfile"));
    setDoctorProfile(p);
    profileNameRef.current.value = p.fullname;
  }, []);

  const [formStatePassword, onInputHandlerPassword] = useForm(
    {
      newPassword: {
        value: "",
        isValid: false,
      },
      lastPassword: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const setNewPassword = (event) => {
    event.preventDefault();
    const newDoctorInfo = {
      newPassword: `${formStatePassword.inputs.newPassword.value}`,
      lastPassword: formStatePassword.inputs.lastPassword.value,
    };
    if (
      formStatePassword.inputs.newPassword.isValid &&
      formStatePassword.inputs.lastPassword.isValid
    ) {
      const localStorageData = JSON.parse(localStorage.getItem("doctor"));
      fetch(Base_url+`v2/changePassword.php`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorageData.token}`,
        },
        body: JSON.stringify(newDoctorInfo),
      })
        .then((res) => {
          if (res.status == 401) {
            localStorage.setItem("doctor", null);
            navigate("/");
            return;
          } else {
            return res.json();
          }
        })
        .then((result) => {
          if (result.token) {
            authContext.doctorLogin({}, result.token);
          }
          if (result.status === 1) {
            swal({
              title: "Password Changed Succussfully",
              icon: "success",
              buttons: "Confirm",
            });
          } else {
            swal({
              title: "Please insert correct informations",
              icon: "error",
              buttons: "Confirm",
            }).then(() => {});
          }
        });
    } else {
      swal({
        title: "Error has been  occurred",
        icon: "error",
        buttons: "Confirm",
      }).then(() => {});
    }
  };
  const newProfile = (event) => {
    event.preventDefault();
    updateProfileApi();
  };
  const uploadFile = async (file) => {
    if (file == null) {
      alert("Please select an image.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("which", "image");

    try {
      let res = await fetch(Base_url+`${uploadPhoto}`, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.status) {
            let d = doctorProfile;
            d.image = result.data;
            setDoctorProfile(d);
          } else {
            swal({
              title: "Error uploading image",
              icon: "error",
              buttons: "confirm",
            });
          }
        });
    } catch (error) {
      console.error("Error uploading image:", error);
      // Handle error, e.g., show an error message to the user.
    }
  };
  const updateProfileApi = () => {
    const newDoctorInfo = {
      fullname: `${profileNameRef.current.value}`,
      image: doctorProfile.image,
    };
    if (profileNameRef.current.value!=='') {

      const localStorageData = JSON.parse(localStorage.getItem("doctor"));
      fetch(Base_url+`v2/editDoctorProfile.php`, {
        method: "POST",
        body: JSON.stringify(newDoctorInfo),
        headers: {
          Authorization: `Bearer ${localStorageData.token}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.status) {
            swal({
              title: "profile updated",
              icon: "success",
              buttons: "confirm",
            });
            let d = doctorProfile;
            doctorProfile.fullname = profileNameRef.current.value;
            setDoctorProfile(d);
            localStorage.setItem("doctorProfile", JSON.stringify(doctorProfile));
          } else {
            swal({
              title: "Error while updating profile",
              icon: "error",
              buttons: "confirm",
            });
          }
        })
        .catch((error) => {
          swal({
            title: "Error while updating profile",
            icon: "error",
            buttons: "confirm",
          });
        });
    }
  };
  return (
    <div className="bg-slate-200">
      <div
        dir="ltr"
        style={{width:"100%",display:"block"}}
        className="container mx-auto h-svh overflow-scroll   md:overflow-hidden"
      >

        <div style={{backgroundColor:"#B3CCD8",display:"flex",alignItems:"center",justifyContent:"end"}} className="profile-section h-1/5 ">
          <div style={{marginInlineEnd:"1vw"}}>
            <Button
                className="p-5 rounded-2xl bg-myblue ms-10 gap-10"
                type="submit"
                onClick={()=>{
                  navigate(-1);
                }}
            >
              <i className="fas fa-arrow-left"></i>
              <span> Back</span>
            </Button>
          </div>
        </div>
        <div className="profile-section h-fit w-fit  md:-mt-32 -mt-20 -mb-20 md:-mb-32  ms-10  flex  items-center gap-5">
          <img
            alt="doctor Image"
            src={`${
              doctorProfile.image !== ""
                ? doctorProfile.image
                : "https://th.bing.com/th/id/OIP.UY0H6jNLhhjKymJWT6HsPwHaHa?rs=1&pid=ImgDetMain"
            }`}
            className=" md:w-60 w-40 h-40  md:h-60 object-cover rounded-full "
          />
          <div style={{marginTop:"25px"}} >
            <div className="font-normal text-2xl md:text-3xl mb-5">
              <span className=" font-bold "> Full name: </span>{" "}
              {doctorProfile.fullname}
            </div>
            <div className="font-normal text-2xl md:text-3xl mb-5">
              <span className=" font-bold "> Email address: </span>{" "}
              {doctorProfile.email}
            </div>
          </div>
        </div>
        <div style={{marginTop:"5rem"}} className="profile-section h-[10%] bg-slate-200 rounded-b-3xl"></div>
        <div className="grid grid-cols-11 justify-center h-max " style={{backgroundColor:"#f5f5db"}}>
          <div className=" col-span-11  md:col-span-5">
            <div style={{backgroundColor:"#f6ddbe",color:"#3f4565",fontFamily:"oldman"}} className=" w-full text-2xl md:text-5xl  p-5 rounded-b-2xl text-center  font-light ">
              Update Profile
            </div>
            <form style={{backgroundColor:"#f5f5db"}}>
              <div className="form-content p-12 space-y-5 text-center">
                <div className="family input">
                  <label className="input-title"> Profile Name</label>
                  <input
                    type="text"
                    className="form__input shadow-2xl focus:border-blue-600 focus:border-[2px]  "
                    id="fullname"
                    ref={profileNameRef}
                    placeholder="Insert profile name"
                  />
                  <span className="error-message text-danger"></span>
                </div>
                <div className="email input justify-center flex bg-slate-200 flex-nowrap  rounded-3xl overflow-hidden  py-2  ">
                  <div className="file">
                    <label style={{backgroundColor:"#38628c",color:"white"}} className="input-title  px-2 py-5 text-xl  me-5 text-emerald-200">
                      Profile image :{" "}
                    </label>
                    <input
                      className=" w-64"
                      type="file"
                      id="file"
                      onChange={(event) => {
                        uploadFile(event.target.files[0]);
                      }}
                    />
                  </div>
                  <span className="error-message text-danger"></span>
                </div>

                <div className="bottom-form flex justify-center items-center ">
                  <div style={{backgroundColor:"#38628c",color:"white",textTransform:"uppercase",paddingInline:"50px",paddingBlock:"10px",margin:"15px"}} className="submit-btn px-5 py-2   bg-slate-300  text-center rounded-2xl border border-slate-700 border-solid">
                    <input
                        type="submit"
                        value="UPDATE PROFILE"
                        onClick={newProfile}
                        className="cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div style={{backgroundColor:"#f3703c"}} className=" hidden  md:block md:col-span-1 w-1 mx-auto"></div>
          <div className=" block   md:hidden md:col-span-1  w-screen mx-auto h-1  bg-blue-500 "></div>
          <div className=" md:col-span-5 col-span-11 ">
            <div style={{backgroundColor:"#f6ddbe",color:"#3f4565",fontFamily:"oldman"}} className=" w-full text-2xl md:text-5xl  p-5 rounded-b-2xl text-center  font-light">
              Change Password
            </div>
            <form style={{backgroundColor:"#f5f5db"}}>
              <div className="form-content p-12  text-center">
                <div className="email input">
                  <label className="input-title">Last Password</label>
                  <Input
                      type="text"
                      className="form__input shadow-2xl  focus:border-blue-600 focus:border-[2px] "
                      id="lastPassword"
                      element="input"
                      validations={[requiredValidator()]}
                      onInputHandler={onInputHandlerPassword}
                      placeholder="Insert last password"
                  />
                  <span className="error-message text-danger"></span>
                </div>
                <div className="family input ">
                  <label className="input-title">New Password</label>
                  <Input
                    type="text"
                    className="form__input shadow-2xl focus:border-blue-600 focus:border-[2px] "
                    id="newPassword"
                    element="input"
                    validations={[requiredValidator()]}
                    onInputHandler={onInputHandlerPassword}
                    placeholder="Insert new password"
                  />
                  <span className="error-message text-danger"></span>
                </div>
                <div className="bottom-form flex justify-center items-center m-4">
                  <div style={{backgroundColor:"#38628c",color:"white",textTransform:"uppercase",paddingInline:"50px",paddingBlock:"10px",margin:"15px"}} className="submit-btn px-5 py-2   bg-slate-300  text-center rounded-2xl border border-slate-700 border-solid">
                    <input
                      type="submit"
                      value="CHANGE PASSWORD"
                      onClick={setNewPassword}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className=" hidden lg:block  absolute top-0 right-0 w-[100px] h-full bg-cyan-400 opacity-40 blur-[100px] rounded-full" />
        <div className=" hidden lg:block  absolute top-0 left-0 w-[100px] h-full bg-cyan-400 opacity-40 blur-[100px] rounded-full" />
      </div>
    </div>
  );
}
