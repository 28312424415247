import React, { useState } from "react";
import "./Sidebar.css";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import AuthContext from "../../context/authContext";
import { Link } from "react-router-dom";

export default function Sidebar() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [active , setActive]=useState("doctor")
  const logoutAdmin = (event) => {
    event.preventDefault();
    swal({
      title: "Log out",
      icon: "success",
      buttons: "confirm",
    }).then(() => {
      authContext.adminLogout();
      navigate("/a-login");
    });
  };

  return (
    <div id="sidebar" className="xl:w-1/6 w-1/4">
      
      <div class="sidebar-menu">
        <ul>
          <li  className={`${active == "doctor" ? "active-menu" : ""}`}>
            <Link to="doctors/1" onClick={()=>setActive("doctor")}>
              <span>Doctors</span>
            </Link>
          </li>
          <li className={`${active == "addDoctor" ? "active-menu" : ""}`}>
            <Link to="doctor "  onClick={()=>setActive("addDoctor")}>
              <span>Add New Doctor</span>
            </Link>
          </li>
          <li className={`${active == "patient" ? "active-menu" : ""}`}>
            <Link to="users/1 "  onClick={()=>setActive("patient")}>
              <span>Patients</span>
            </Link>
          </li>
          
          <li>
            <a href="#" onClick={logoutAdmin}>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
