import React, {useEffect, useRef, useState} from "react";
import DoctorTopbar from "../../Components/DoctorTopbar/DoctorTopbar";
import "./UserPage.css";

import {
  Base_url,
  addCommentApi,
  deleteSpotByDocter,
  getPatientBodySpots, getSpotDataAnalyze, getSpotDataComments,
  getSpotImages, getSpotImagesv3, getSpotMessages,
  getUserData,
  insertSpotByDocter,
  insertSpotDataByDocter, sendMessage,
  uploadPhoto
} from "../../Config/api/api";
import Button from "../../Components/Form/Button";
import swal from "sweetalert";
import CircleSpinner from "../../Components/CircleSpinner/CircleSpinner";
import {useNavigate, useParams} from "react-router-dom";
import BarChart from "../../Components/Chart/Chart";
import DatePicker, {DateObject} from "react-multi-date-picker";
import woman_front from "../../Assets/photos/weman_front.png"
import woman_back from "../../Assets/photos/woman_back.png"
import man_front from "../../Assets/photos/man_front.png"
import man_back from "../../Assets/photos/man_back.png"
import clear from "../../Assets/photos/clear.png"
import ImageComponent from "./chart";
import ChatButton from "../../Components/Chat/chatButton";

export default function UserPage() {
  const [addSpotCheck, setAddSpotCheck] = useState("close");
  const [userProfile, setUserProfile] = useState({});
  const [bodySide, setBodySide] = useState("front");
  const [bodySpots, setBodySpots] = useState([]);
  const [allSpotData, setAllSpotData] = useState([]);
  const [spotId, setSpotId] = useState();
  const [currentXAndY, setCurrentXAndY] = useState({x:0,y:0});
  const [currentSpotData, setCurrentSpotData] = useState(null);
  const [bodyImage, setBodyImage] = useState(woman_front);
  const [clickedSpot, setClickedSpot] = useState();
  const [analyze, setAnalyze] = useState(null);
  const [messages, setMessages] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const filteredData = allSpotData.filter((item) => {
    const itemDate = new DateObject(item.insertTime);
    return (
        startDate==null || endDate==null || (itemDate.toDays() >= startDate.toDays()) && (itemDate.toDays() <= endDate.toDays())
    );
  });
  const [isImgShow, setIsImgShow] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const commentInputRef = useRef(null);
  const params = useParams();

  const onImageLoaded = () => setIsImgShow(true);
  const closeChatWindow = () => {
    setIsChatOpen(false);
  };

  const toggleChatWindow = () => {
    setIsChatOpen(!isChatOpen);
  };
  const onSideClick = (side) => {
    setBodySide(side);
    setBodyPhoto(userProfile.gender,side);
  };
  const handleFileChange =  (event) => {
    console.log("file"+event.target.files[0]);
    uploadFile(event.target.files[0]);
  };
  const uploadFile = async (file) => {

    if (file==null) {
      alert('Please select an image.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('which', "image");

    try {
      let res = await fetch(`${Base_url + uploadPhoto}`, {
        method: "POST",
        body: formData
      }).then(res=>res.json())
          .then(result=>{
            if (result.status) {
              if(addSpotCheck==="open"){
                insertNewSpot(true,result.data);
              }else if(addSpotCheck==="spotClicked"){
                addSpotDataToSpotApi(result.data);
              }
            }else{
              swal({
                title: "Error uploading image",
                icon: "error",
                buttons: "confirm",
              });
            }
          })
    } catch (error) {
      console.error('Error uploading image:', error);
      // Handle error, e.g., show an error message to the user.
    }
  };
  const handleButtonClick = () => {
    // Click the file input programmatically
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const spotClicked = (spot) => {
    console.log("clicked spot"+spot.spotId)
    if (spotId !== spot.spotId) {
      closeChatWindow();
      setSpotId(spot.spotId);
      setClickedSpot(spot.spotId);
      setAddSpotCheck("spotClicked");
      setStartDate(null);
      setEndDate(null);
      setCurrentSpotData(null);
    }
  };
  const insertNewSpotApi =  (isWithPhoto,url) => {
    let body = isWithPhoto ? {
      gender: userProfile.gender,
      side: bodySide,
      x: currentXAndY.x,
      y: currentXAndY.y,
      spotData: {
        image_url: url
      }
    } : {
      gender: userProfile.gender,
      side: bodySide,
      x: currentXAndY.x,
      y: currentXAndY.y
    };
    const localStorageData = JSON.parse(localStorage.getItem("doctor"));
    fetch(`${Base_url + insertSpotByDocter}?userId=${params.userid}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorageData.token}`,
      },
    }).
    then(response => {return response.json()})
        .then(result => {
          console.log(result);
          if (result.statusCode === 401){
            localStorage.setItem("doctor", "");
            navigate('/', { replace: true });
            return;
          }
          if (result.status) {
            swal({
              title: "new spot added",
              icon: "success",
              buttons: "confirm",
            });
            const newSpot = { spotId: result.data,x:body.x,y:body.y,gender:body.gender,side:body.side };
            setBodySpots((prevBodySpots) => [...prevBodySpots, newSpot]);
            setAddSpotCheck("close");
            setCurrentXAndY({x:0,y:0});
            setClickedSpot(null);
            if(isWithPhoto){
              spotClicked(newSpot);
            }
            console.log(bodySpots);
          }else{
            swal({
              title: "Error while adding spot",
              icon: "error",
              buttons: "confirm",
            });
          }
        }).catch(error=>{
      console.log(error.message)
    });
  };
  const deleteSpotApi =  (spotId) => {
    let localStorageData = JSON.parse(localStorage.getItem("doctor"));
    fetch(`${Base_url + deleteSpotByDocter}?userId=${params.userid}&spotId=${spotId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorageData.token}`,
      },
    }).
    then(response => {return response.json()})
        .then(result => {
          console.log(result);
          if (result.statusCode === 401){
            localStorage.setItem("doctor", "");
            navigate('/', { replace: true });
            return;
          }
          if (result.status) {
            swal({
              title: "Spot deleted",
              icon: "success",
              buttons: "confirm",
            });
            setAddSpotCheck("close");
            setCurrentXAndY({x:0,y:0});
            setClickedSpot(null);
            setSpotId(null);
            reloadSpots();
          }else{
            swal({
              title: "Error while deleting spot",
              icon: "error",
              buttons: "confirm",
            });
          }
        }).catch(error=>{
      console.log(error.message)
    });
  };

  const addSpotDataToSpotApi =  (url) => {
    const body = {
      image_url:url
    };

    const localStorageData = JSON.parse(localStorage.getItem("doctor"));
    fetch(`${Base_url + insertSpotDataByDocter}?spotId=${spotId}&userId=${params.userid}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorageData.token}`,
      },
    }).
    then(response => {return response.json()})
        .then(result => {
          console.log(result);
          if (result.statusCode === 401){
            localStorage.setItem("doctor", "");
            navigate('/', { replace: true });
            return;
          }
          if (result.status) {
            swal({
              title: "new photo added to spot",
              icon: "success",
              buttons: "confirm",
            });
            setAllSpotData(()=>[...allSpotData,result.data]);
          }else{
            swal({
              title: "Error while adding photo",
              icon: "error",
              buttons: "confirm",
            });
          }
        }).catch(error=>{
      console.log(error.message)
    });
  };

  const addMessage =  (content) => {

    console.log(process.env.REACT_FLAVOR+"\nkirrrrrrrrrrrr\n\n\n");

    const body = {
      content:content,
      receiverId:params.userid
    };

    let localStorageData = JSON.parse(localStorage.getItem("doctor"));
    fetch(`${Base_url + sendMessage}?id=${spotId}`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorageData.token}`,
      },
    }).
    then(response => {return response.json()})
        .then(result => {
          console.log(result);
          if (result.statusCode === 401){
            localStorage.setItem("doctor", "");
            navigate('/', { replace: true });
            return;
          }
          if (result.status) {
            setMessages(() => [...messages, {
                content: content,
                doctorName: "",
                doctorProfile: "",
                id: result.data,
                insertDate: "",
                sender: "doctor",
                userName: "",
                userProfile: ""}]);
            commentInputRef.current.value ="";
          }else{
            swal({
              title: "Error while sending message",
              icon: "error",
              buttons: "confirm",
            });
          }
        }).catch(error=>{
          console.log(error.message)
        });
  };

  const reloadSpots =  (url) => {
    console.log("reload spots");
    let localStorageData = JSON.parse(localStorage.getItem("doctor"));

    fetch(
        `${Base_url + getPatientBodySpots}?id=${params.userid}&gender=${
            userProfile.gender
        }&side=${bodySide}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorageData.token}`,
          },
        }
    )
        .then((response) => response.json())
        .then((result) => {
          setBodySpots(result.data.spots);
        })
        .catch((error) => console.error(error))
  };

  const insertNewSpot= (isWithPhoto,url) => {
    if(isWithPhoto){
      if(addSpotCheck==="open" && url!==""){
        insertNewSpotApi(isWithPhoto,url);
      }
    }else{
      if(addSpotCheck==="open"){
        insertNewSpotApi(isWithPhoto,"");
      }
    }
  }

  useEffect(() => {
    console.log(process.env.REACT_FLAVOR+"\nkirrrrrrrrrrrr\n\n\n");
    if(currentSpotData!=null){
      const localStorageData = JSON.parse(localStorage.getItem("doctor"));
      fetch(`${Base_url + getSpotDataAnalyze}?analyzeId=${currentSpotData.analizeId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorageData.token}`,
        },
      })
          .then((response) => response.json())
          .then((result) => {
            if(result.status){
              console.log(result.data);
              setAnalyze(result.data);
            }
          })
          .catch((error) => console.error(error));
    }
  }, [currentSpotData]);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("doctor"));

    fetch(`${Base_url + getUserData}?id=${params.userid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorageData.token}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setUserProfile(result.data.profile);
        setBodyPhoto(result.data.profile.gender,"front");
        console.log("userProfile", result.data.profile);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("doctor"));

    fetch(
      `${Base_url + getPatientBodySpots}?id=${params.userid}&gender=${
        userProfile.gender
      }&side=${bodySide}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorageData.token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        setBodySpots(result.data.spots);
      })
      .catch((error) => console.error(error));
  }, [userProfile, bodySide]);

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("doctor"));

    fetch(
        `${Base_url + getSpotImagesv3}?id=${params.userid} ${
            spotId != null ? `&spotId=${spotId}` : null
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorageData.token}`,
          },
        }
    )
        .then((response) => response.json())
        .then((result) => {
          setAllSpotData(result.data);
        })
        .catch((error) => console.error(error));


    if(spotId!=null){
      fetch(`${Base_url + getSpotMessages}?id=${spotId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorageData.token}`,
        },
      })
          .then((response) => response.json())
          .then((result) => {
            if(result.status){
              console.log(result.data);
              setMessages(result.data);
            }
          })
          .catch((error) => console.error(error));
    }

  }, [spotId]);

  const setBodyPhoto = (userGender, bodySide) => {
    console.log(userGender);
    if (userGender === "Female") {
      if (bodySide === "front") {
        setBodyImage(woman_front);
      }else{
        setBodyImage(woman_back);
      }
    } else {
      if (bodySide === "front") {
        setBodyImage(man_front);
      }else{
        setBodyImage(man_back);
      }
    }
  };

  // const CommentList = () => {
  //   return (
  //       <div className="container mx-auto my-8 p-4 bg-white shadow-lg rounded-lg">
  //         <h2 className="text-3xl font-bold mb-6 text-center text-blue-900">Comments</h2>
  //         {comments.length > 0 ? (
  //             comments.map((comment, index) => (
  //                 <div key={index} className="mb-4 p-4 border-b border-gray-300">
  //                   <div className="flex justify-between items-center mb-2">
  //                     <span className="text-xl font-semibold text-green-700">{comment.doctorName}</span>
  //                     <span className="text-sm text-gray-500">{comment.insertDate}</span>
  //                   </div>
  //                   <p className="text-lg text-gray-800">{comment.comment}</p>
  //                 </div>
  //             ))
  //         ) : (
  //             <div className="text-center text-xl text-gray-500">
  //               No comments available.
  //             </div>
  //         )}
  //       </div>
  //   );
  // };

  const DiagnosisSection   = () =>{
    return (
        <>
          {
            currentSpotData!=null?<>
              <div
                  className={`diagnosis-title font-bold lg:text-start text-center text-6xl p-5 row-span-1 m-5 text-cyan-900`}
              >
              </div>
              <div className="flex items-center">
                <div className="w-1.5 h-20 bg-cyan-900 mr-4"></div> {/* Vertical Line */}
                <div className="checkup-title font-bold text-6xl pl-4  text-cyan-900">
                  Diagnosis result
                </div>
              </div>
              <div
                  className={`checkup-title grid grid-rows-2 grid-cols-5 h-[600px] gap-10 sm:gap-x-10 justify-center items-center text-center ${
                      currentSpotData.image_url!= null ? "block" : "hidden"
                  }`}
              >
                <div className="diagnosis-image col-span-5 lg:col-span-2 max-h-[400px] justify-center text-center flex">
                  <img
                      src={currentSpotData.image_url}
                      className="h-[400px] rounded-full border-[5px] border-solid "
                  />
                </div>
                <div
                    className="diagnosis-chart col-span-5 lg:col-span-3"
                    style={{
                      border: "2px solid gray",
                      padding: "1rem",
                      borderRadius: "0.5rem",
                    }}
                >
                  {(analyze!==null) && (
                      <BarChart sickness={analyze} />
                  )}
                  {currentSpotData.analyzed===false && (
                      <div className="text-5xl text-red-600">
                        Information Not Found
                      </div>
                  )}
                </div>
                <div
                    className="comment col-span-5"
                    style={{
                      justifyContent: "start",
                      alignItems: "center",
                      justifySelf: "center",
                    }}
                >
                </div>
              </div>
            </>:<div/>
          }
        </>
    );
  }

  const CheckUpsSection   = () =>{
    return (
        <>
          <div className="flex items-center pb-4">
            <div className="w-1.5 h-20 bg-cyan-900 mr-4"></div> {/* Vertical Line */}
            <div className="checkup-title font-bold text-6xl pl-4  text-cyan-900">
              Check-Ups
            </div>
          </div>
          <div
              className={`item-wrapper row-span-1 bg-emerald-100 rounded-2xl p-3 shadow-lg ${
                  allSpotData.length === 0 ? "items-center justify-center" : ""
              }`}
          >
            {/*{!isImgShow && allSpotData.length? <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>*/}
            {/*  <CircleSpinner />*/}
            {/*</div>:}*/}
            {
              filteredData.map(
                  (spotData, index) =>
                      spotData.image_url && (
                          <div
                              key={index}
                              className="item text-cyan-500 flex flex-wrap gap-3 rounded-2xl"
                          >
                            <img
                                src={spotData.image_url}
                                alt={`Image ${index + 1}`}
                                id={JSON.stringify(spotData)}
                                onLoad={onImageLoaded}
                                className="item-image cursor-pointer block shadow-md"
                                onClick={() => {
                                  console.log(spotData);
                                  setCurrentSpotData(spotData);
                                }}
                            />
                            <span>{spotData.insertTime}</span>
                          </div>
                      )
              )
            }
            {!allSpotData.length && (
                <div className="text-5xl text-red-600">Image Not Found</div>
            )}
          </div>
        </>
    );
  }

  const FilterSection   = () =>{
    return (
        <>
          <div className="user-info row-start-1 flex row-end-2 justify-start items-center gap-10 p-5">
            <img
                alt="user image"
                src={`${
                    userProfile.image !== ""
                        ? userProfile.image
                        : "https://th.bing.com/th/id/OIP.UY0H6jNLhhjKymJWT6HsPwHaHa?rs=1&pid=ImgDetMain"
                }`}
                className="object-cover rounded-full border border-solid w-32 h-32"
            />
            <div className="username text-center font-bold text-5xl text-blue-950">
              {userProfile.firstname} {userProfile.lastname}
            </div>
          </div>
          <div className="image-type grid grid-cols-4 p-4 gap-1 space-y-1 text-center">
            <div className="image-type-title col-span-4 text-3xl font-bold text-start">
              Image type
            </div>
            <div className="button-one border border-solid col-start-1 col-end-3 rounded-lg cursor-pointer">
              Clinical
            </div>
            <div className="button-two border border-solid col-start-3 col-end-5 rounded-lg cursor-pointer">
              Dermoscopic
            </div>
          </div>
          <div className="image-tag grid grid-cols-2 p-4 gap-1 space-y-1 text-center">
            <div className="image-type-title col-span-2 text-3xl font-bold text-start">
              Image Tag
            </div>
            <div className="button-one border col-span-2 border-solid rounded-lg cursor-pointer text-start py-1 px-2 font-bold">
              Doctor-check
            </div>
          </div>
          <div className="image-Date p-4 gap-1 space-y-1 text-center flex  flex-wrap justify-center">
            <div className="image-type-title text-3xl font-bold text-center">
              Image Date
            </div>
            <div className="border border-solid p-4 rounded-2xl space-y-4 p-8">
              <div className="w-full">
                Start Time :
                <DatePicker
                    value={startDate}
                    onChange={setStartDate}
                    containerClassName="calender"
                    placeholder="YYYY/MM/DD"

                />
              </div>
              <div className="w-full">
                End Time :
                <DatePicker
                    value={endDate}
                    onChange={setEndDate}
                    containerClassName="calender"
                    placeholder="YYYY/MM/DD"
                />
              </div>
            </div>
          </div>
        </>
    );
  }

  const SpotControlPanel   = () =>{
    return (
        <>
          {
            addSpotCheck === "open"?
                <div
                    className={`add-spot grid grid-cols-2 p-4 gap-3 text-center`}>
                  <div>
                    <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        style={{ display: 'none' }} // Hide the input visually
                        onChange={handleFileChange}
                    />
                    <div
                        className="button-one hover:scale-[1.02] transition-all text-lg col-span-1 bg-green-300 border border-solid p-2 rounded-lg gap-3 cursor-pointer items-center flex justify-center"
                        onClick={handleButtonClick}
                    >
        <span className="text-5xl text-green-800">
          <i className="fa-solid fa-camera"></i>
        </span>
                      ADD SPOT WITH PHOTO
                    </div>
                  </div>
                  <div
                      onClick={()=>{
                        insertNewSpot(false);
                      }}
                      className="hover:scale-[1.02] transition-all text-2xl button-one border border-solid bg-green-300 items-center flex justify-center gap-7 col-span-1 p-2 rounded-lg cursor-pointer">
                    ADD SPOT
                  </div>
                </div>
                :addSpotCheck === "spotClicked"?
                    <div
                        style={{justifyContent:"center",alignItems:"center",display:"flex"}}
                        className={`add-spot grid grid-cols-2 p-4 gap-3 text-center`}>
                      <div>
                        <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: 'none' }} // Hide the input visually
                            onChange={handleFileChange}
                        />
                        <div
                            className="button-one hover:scale-[1.02] transition-all text-lg col-span-1 bg-green-300 border border-solid p-2 rounded-lg gap-3 cursor-pointer items-center flex justify-center"
                            onClick={handleButtonClick}>
                            <span className="text-5xl text-green-800">
                              <i className="fa-solid fa-camera"></i>
                            </span>
                          ADD PHOTO TO SPOT
                        </div>
                      </div>
                      <div>
                        <div
                            style={{backgroundColor:"#ed2a2a"}}
                            className="button-one hover:scale-[1.02] transition-all text-lg col-span-1 border border-solid p-2 rounded-lg gap-3 cursor-pointer items-center flex justify-center"
                            onClick={()=>{
                              deleteSpotApi(spotId);
                            }}>
                            <span className="text-5xl">
                              <i className="fa-solid fa-trash"></i>
                            </span>
                          <div style={{color:"black"}}>
                            DELETE SPOT
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div style={{height:"70px"}} className={`add-spot grid grid-cols-2 p-4 gap-3 text-center `}></div>
          }
        </>
    );
  }

  return (
      <>
        <div className="bg-cyan-100 h-svh overflow-y-scroll overflow-x-hidden">
          <div className="container mx-auto">
          <div dir="rtl">
            <DoctorTopbar profile={JSON.parse(localStorage.getItem("doctorProfile"))}/>
          </div>
            <div
                dir="ltr"
                className="main-content grid grid-cols-12 gap-10 justify-center ">
              <div
                  dir="ltr"
                  className="shadow-xl h-max side-bar bg-emerald-100 col-start-2 col-end-12 sm:col-start-3 sm:col-end-11 md:col-start-4 md:col-end-10 xl:col-span-3 lg:col-span-4 min-w-[300px] grid border-dashed border border-green-600 rounded-xl auto-rows-min gap-2">

                <FilterSection/>
                <>
                  <div className="justify-center text-center">
                    <div
                        style={{ backgroundColor: "white" }}
                        className={`body-image min-w-[194px] h-[500px] inline-block relative top-0`}
                    >
                      <ImageComponent
                          onShowControls = {(which)=>{
                            setAddSpotCheck(which);
                            setClickedSpot(null);
                            setSpotId(null);
                            setEndDate(null);
                            setStartDate(null);
                            setCurrentSpotData(null);
                          }}
                          setCurrentXAndY = {(data)=>{
                            setCurrentXAndY(data);
                          }}
                          bodyImage={bodyImage}/>
                        <>
                          {
                          addSpotCheck==="open"?<img
                            src={clear}
                            style={{
                              position: "absolute",
                              width:"16px",
                              height:"16px",
                              top: currentXAndY.y - 2.5,
                              left: currentXAndY.x - 2.5,
                            }}></img>:<div/>
                          }
                        </>
                      {bodySpots.map((myspot) => (
                          <div
                              className="cursor-pointer"
                              onClick={()=>{
                                spotClicked(myspot);
                              }}>
                            <i
                                className={`rounded-full  w-5 h-5`}
                                //id={spot.spotId}
                                style={{
                                  backgroundColor: clickedSpot === myspot.spotId ? '#ff634e' : '#38628C',
                                  hover: {
                                    backgroundColor: 'red-500',
                                    color: 'red-200',
                                  },
                                  position: "absolute",
                                  top: myspot.y - 2.5,
                                  left: myspot.x - 2.5,
                                }}
                            ></i>
                          </div>
                      ))}
                    </div>
                  </div>
                  <div className="body-direction grid grid-cols-5 p-4 gap-3 text-center">
                    <div

                        className={`button-one col-start-2 col-end-3 border border-solid p-2 rounded-lg ${
                            bodySide === "front" ? "bg-gray-400" : "bg-white"
                        }`}
                        style={{ cursor: "pointer",
                          color:bodySide==="front"?"#38628c":"#B338628C",

                          backgroundColor:bodySide==="front"?"#B3CCD8":"white"}}
                        onClick={() => {
                          if (bodySide === "back") {
                            onSideClick("front");
                          }
                        }}
                    >
                      Front
                    </div>
                    <div
                        className={`button-one border border-solid col-start-4 col-end-5 p-2 rounded-lg ${
                            bodySide === "back" ? "bg-gray-400" : "bg-white"
                        }`}
                        style={{ cursor: "pointer",
                          color:bodySide==="back"?"#38628c":"#B338628C",
                          backgroundColor:bodySide==="back"?"#B3CCD8":"white"}}
                        onClick={() => {
                          if (bodySide === "front") {
                            onSideClick("back");
                          }
                        }}
                    >
                      Back
                    </div>
                  </div>
                </>
                <SpotControlPanel/>

              </div>
              <div
                  dir="ltr"
                  className="main col-span-8 lg:col-span-8 col-start-2 col-end-12 sm:col-start-3 sm:col-end-11 md:col-start-4 md:col-end-10 grid-rows-4 auto-rows-auto">

                <CheckUpsSection/>
                <DiagnosisSection />

              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block absolute top-0 right-0 w-[100px] h-full bg-cyan-500 opacity-40 blur-[120px] rounded-full" />
        <div className="hidden lg:block absolute top-0 left-0 w-[100px] h-full bg-cyan-500 opacity-40 blur-[120px] rounded-full" />

        {
            spotId!=null && <ChatButton
                messages={messages}
                isChatOpen={isChatOpen}
                toggleChatWindow={()=>{
                  toggleChatWindow();
                }}
                addMessage={
                    (content)=>{
                        addMessage(content);
                    }
                }/>
        }

      </>
  );
}
