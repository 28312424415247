import { createContext } from "react";

const AuthContext = createContext({
    doctorIsLoggedIn:false,
    adminIsLoggedIn:false,
    doctorToken:null,
    adminToken:null,
    doctorInfos:null,
    adminInfos:null,
    doctorLogin:()=>{},
    adminLogin:()=>{},
    doctorLogout:()=>{},
    adminLogout:()=>{}

})

export default AuthContext