import React, {useRef, useState} from "react";
import {sd} from "../../Assets/photos/user_ic.png"
import {Navigate, useNavigate } from "react-router-dom";
import deleteIcon from '../../Assets/photos/delete.png';
export default function DoctorBox({user,deleteDoctor,setDescApi}) {
  const navigate =useNavigate()
    const [isImgShow, setIsImgShow] = useState(false);
    const [isTickShow, setIsTickShow] = useState(false);
    const [description, setDescription] = useState(user.description);
    return (
    <tr 
    className= " hover:bg-slate-300 divide-x devide-solid  hover:cursor-pointer w-full items-center grid grid-cols-12   gap-3 px-6 py-4 font-normal text-gray-800 text-left lg:text-lg text-sm align-middle ">
        <td id="image" className=" col-span-1  py-4 left-0" >
            <img
                onClickCapture={(event)=>{
                  event.preventDefault()
                    deleteDoctor();
                }}
                style={{width:'24px'}}
                src={deleteIcon}
            />
        </td>
        <td className=" border-neutral-500 col-span-3 py-4">
            <div style={{display:"flex",flexDirection:"row"}}>

                {
                    isTickShow?
                    <div
                        onClick={()=>{
                            setDescApi(description);
                            user.description = description;
                            setIsTickShow(false);
                        }}>
                        <i
                            style={{marginInline:'10px'}} className="fa fa-check-circle fa-2x"/>
                        </div>:
                        <i
                            style={{marginInline:'10px'}} />
                }

                <input
                    className="p-2  w-[94%]    "
                    type="text"
                    dir={'ltr'}
                    value={description}
                    onChange={(event)=>{
                        setDescription(event.target.value);
                        setIsTickShow(description!==user.description);
                    }}
                />
            </div>
        </td>
        <td className=" border-neutral-500 col-span-2 py-4" onClick={() => navigate("/p-admin/doctor/"+user.id+"/users/1")}>
            {user.password=="" ? "-" :user.password}
        </td>
        <td className=" border-neutral-500 col-span-2 py-4" onClick={() => navigate("/p-admin/doctor/"+user.id+"/users/1")}>
      {user.email=="" ? "-" :user.email}
      </td>
      <td className=" border-neutral-500 col-span-2 py-4" onClick={() => navigate("/p-admin/doctor/"+user.id+"/users/1")}>
      {user.fullname=="" ? "-" :user.fullname}
      </td>
      <td className=" border-neutral-500 col-span-1 py-4" onClick={() => navigate("/p-admin/doctor/"+user.id+"/users/1")}>
      {user.id=="" ? "-" :user.id}
      </td>

      <td id="image" className=" border-neutral-500 float-start  col-span-1   py-4 left-0" onClick={() => navigate("/p-admin/doctor/"+user.id+"/users/1")}>
        <img
      
          src={`${user.image != "" ? user.image :"https://th.bing.com/th/id/OIP.UY0H6jNLhhjKymJWT6HsPwHaHa?rs=1&pid=ImgDetMain"}`}
          alt="patient's image"
          className="  rounded-full object-cover object-center  h-16 w-16 left-0"
        />
      </td>
    </tr>
  );
}
