import React, { useRef, useEffect } from 'react';

const ImageComponent = ({ bodyImage, onImageLoaded ,onShowControls,setCurrentXAndY}) => {
    const canvasRef = useRef(null);
    const imgRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const img = imgRef.current;

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            context.drawImage(img, 0, 0, img.width, img.height);
            if (onImageLoaded) {
                onImageLoaded();
            }
        };
    }, [bodyImage, onImageLoaded]);

    const handleCanvasClick = (event) => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const rect = canvas.getBoundingClientRect();

        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const pixelData = context.getImageData(x, y, 1, 1).data;
        const [r, g, b, a] = pixelData;

        if(r === 209 && g ===250){
            console.log(`Clicked at: outside`);
            onShowControls("close");
        }else{
            console.log(`Clicked at: inside`);
            onShowControls("open");
            setCurrentXAndY({x:x,y:y})
        }
        console.log(`Clicked at: (${x}, ${y})`);
    };

    return (
        <div>
            <img
                ref={imgRef}
                src={bodyImage}
                alt="user body"
                style={{ display: 'none' }}
            />
            <canvas
                ref={canvasRef}
                className="inline-block -z-10 h-full bg-slate-300"
                onClick={handleCanvasClick}
            />
        </div>
    );
};

export default ImageComponent;
