import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../Components/Sidebar/Sidebar";
export default function index() {
  return (
    <>
      <div id="content" className="w-full grid grid-cols-12">
        <div className="col-span-3 xl:col-span-2">
          <Sidebar />
        </div>
        <div id="home" class="xl:col-span-10 col-span-9">
          <div class="cotnainer-fluid" id="home-content">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
