import React from "react";
import Logo from "../Logo/Logo";
import Searchbox from "../SearchBox/Searchbox";
import swal from "sweetalert";
import AuthContext from "../../context/authContext.js";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";

const DoctorTopBar = ({profile}) =>{
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const signOut = (event) => {
    event.preventDefault();
    swal({
      title: "sign out successfully",
      icon: "success",
      buttons: "confirm",
    }).then(() => {
      authContext.doctorLogout();
      navigate("/d-login");
    });
  };
  const goToPatientsList = (event) => {
    event.preventDefault();
    navigate("/p-doctor/1");
  };
  return (
    <>
      <div className="topbar">
        <div className="topbar-content grid grid-cols-12  gap-4  md:gap-5   items-center p-10 lg:text-xl text-sm text-center">

          <div
              onClick={signOut}
              className=" topbar-signout p-3 col-span-2 sm:col-span-1 transition-all overflow-hidden cursor-pointer text-center rounded-full sm:rounded-3xl hover:bg-neutral-200 hover:border-neutral-500  border border-solid hover:text-neutral-500  text-white bg-neutral-500"
          >
            {" "}
            <i className="fa fa-sign-out" aria-hidden="true"></i>
            <span className="hidden md:inline-block px-2">Sign Out</span>{" "}
          </div>
          <div className=" topbar-doctor-info col-span-2  flex justify-center items-center  gap-5 " style={{cursor:"pointer"}} onClick={()=>navigate("/editProf")}>
            <div className="hidden md:block info-name text-center">
              {profile.fullname+" "}
            </div>
            <div className="info-image " >
              <img
                  alt="doctor Image"
                  src={profile.image !== ""
                      ? profile.image
                      : "https://th.bing.com/th/id/OIP.UY0H6jNLhhjKymJWT6HsPwHaHa?rs=1&pid=ImgDetMain"}
                  className=" w-20 h-20 object-cover rounded-full"
              />
            </div>
          </div>
          <div
              onClick={goToPatientsList}
              className=" h-fit topbar-patients md:block hidden md:col-span-1 py-3   transition-all overflow-hidden cursor-pointer text-green-500 border-green-500 hover:text-white hover:border-white hover:bg-green-500 text-center border-solid border rounded-3xl">
            Patients
          </div>
          <div className=" topbar-doctor-image sm:block hidden xl:col-span-2"></div>

          <div className=" sm:block   hidden  topbar-icon col-start-11 col-end-13 lg:col-start-12">
            <Logo />
          </div>
        </div>
      </div>
    </>
  );
}

export  default  DoctorTopBar;