import React, { useState } from 'react';
import ChatWindow from "./chatLayout";

const ChatButton = ({isChatOpen,toggleChatWindow,messages,addMessage}) => {


  return (
      <div className="fixed bottom-8 right-[15vw] flex">
        <button
            onClick={()=>{
                toggleChatWindow();
            }}
            className="bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300 flex items-center">
          <i className="fa fa-comments p-2"></i>
           <div className='p-2'>Chat</div>
        </button>

        {isChatOpen && (
            <ChatWindow chatMessages={messages} addMessage={addMessage}/>

        )}
      </div>
  );
};

export default ChatButton;
