import React from "react";
import Login from "./Pages/Login/Login";
import AdminLogin from "./Pages/AdminLogin/AdminLogin";
import Index from "./Pages/Index";
import UserPage from "./Pages/UserPage/UserPage"
import DoctorPanel from "./Pages/DoctorPanel/DoctorPanel"; 
import AdminPanel from "./Pages/AdminPanel/index"
import NotFound from "./Pages/colorlib-error-404-15/NotFound";
import AdminUserList from "./Components/AdminPanel/AdminUserList/AdminUserList"
import AddDoctor from "./Components/AdminPanel/AddDoctor/AddDoctor";
import AdminDoctorList from "./Components/AdminPanel/AdminDoctorList/AdminDoctorList";
import DoctorUserList from "./Components/AdminPanel/DoctorUsersList/DoctorUsersList";
import EditProfileNew from "./Pages/EditProfile/EditProfileNew"
const routes = [
   { path: "/", element: <Index /> },
  { path: "/d-login", element: <Login /> },
  { path: "/a-login", element: <AdminLogin /> },
  {
    path: "/p-doctor/:page",
    element: <DoctorPanel />,
  },

  { path: "/editProf", element: <EditProfileNew /> },

  {path:"/user/:userid" , element:<UserPage />},
  {path:"*" , element:<NotFound />},
  {
    path: "/p-admin",
    element: <AdminPanel />,
    children: [
      { path: "doctors/:page", element: <AdminDoctorList /> },
      { path: "doctor/:doctorId/users/:page", element: <DoctorUserList /> },
      { path: "users/:page", element: <AdminUserList /> },
      { path: "doctor", element: <AddDoctor /> },
    ],
  },
];

export default routes;
