import React from "react";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import Logo from "../../Logo/Logo";
import { useContext } from "react";
import AuthContext from "../../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../Pagination/Pagination";
import {
  requiredValidator,
  minValidator,
  maxValidator,
  emailValidator,
} from "../../../validators/rules";
import {Base_url, deleteDoctor, insertSpotByDocter, setDescription} from "../../../Config/api/api";
import DoctorBox from "../../UserList/DoctorBox";
import "./AdminDoctorList.css";
import {Modal} from "react-bootstrap";
export default function AdminDoctorList() {
  const [shownUsers, setShownUsers] = useState([]);
  const [total, setTotal] = useState(9);
  const [limit, setLimit] = useState(6);
  const [users, setUsers] = useState([]);
  const [gender, setGender] = useState("");
  const [page, setPage] = useState(1);
  const [searchinput, setSearchInput] = useState("");
  const authContext = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const params = useParams();
  const [doctorProfile, setDoctorProfile] = useState([]);
  const genderHandler = async (event) => {
    let selectedGender = event.target.innerText;
    navigate("/p-doctor/1");
    if (selectedGender === "both") {
      setGender("");
    } else {
      setGender(selectedGender);
    }

    // Call genderRequest with the updated gender value
  };
  const handleSearchChange = (event) => {
    console.log(event.target);
    setSearchValue(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    setSearchInput(searchValue);
  };
  const signOut = (event) => {
    event.preventDefault();
    swal({
      title: "sign out successfully",
      icon: "success",
      buttons: "confirm",
    }).then(() => {
      authContext.adminLogout();
      navigate("/login");
    });
  };
  const deleteDoctorApi =  (id) => {
    const localStorageData = JSON.parse(localStorage.getItem("admin"));
    swal({
      title: "Are you sure about removing this doctor?",
      icon: "warning",
      buttons: ["no", "yes"],
    }).then((result) => {
      if (result) {
        fetch(`${Base_url + deleteDoctor}?id=${id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorageData.token}`,
          },
        }).
        then(res => {
              if (res.status == 401) {
                localStorage.setItem("admin",null);
                navigate("/a-login");
                return;
              }else{
                return res.json()
              }
            }
        )
            .then(result => {

              if(result.token){
                authContext.adminLogin({}, result.token);
              }
              if (result.status) {
                swal({
                  title: "Doctor deleted successfully",
                  icon: "success",
                  buttons: "confirm",
                });
                const updatedItems = users.filter((a, i) => id !== a.id);
                setUsers(updatedItems);
                setTotal(total-1);
              }else{
                swal({
                  title: "Error while deleting doctor from database",
                  icon: "error",
                  buttons: "confirm",
                });
              }
            }).catch(error=>{
          console.log(error.message)
        })
      }
    })


  };

  const setDescApi =  (desc,id) => {
    let localStorageData = JSON.parse(localStorage.getItem("admin"));
    fetch(`${Base_url + setDescription}?id=${id}`, {
      method: "POST",
      body: JSON.stringify({description:desc}),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorageData.token}`,
      },
    }).
    then(res => {
          if (res.status === 401) {
            localStorage.setItem("admin",null);
            navigate("/a-login");
            return;
          }else{
            return res.json()
          }
        }).
    then(result => {

          if(result.token){
            authContext.adminLogin({}, result.token);
          }
          if (result.status) {
            swal({
              title: "Description saved",
              icon: "success",
              buttons: "confirm",
            });
          }else{
            swal({
              title: "Error while setting doctor description",
              icon: "error",
              buttons: "confirm",
            });
          }
        }).catch(error=>{
      console.log(error.message)
    })
  };

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("admin"));
    console.log("localStorageData", localStorageData);

    fetch(
      Base_url +
        `v2/getAllDoctor.php?limit=${limit}&page=${params.page}&gender=${gender}&search=${searchinput}`,
      {
        headers: {
          Authorization: `Bearer ${localStorageData.token}`,
        },
      }
    )
      .then((res) =>{
        if (res.status == 401) {
          localStorage.setItem("admin",null);
          navigate("/a-login");
          return;
        }else{
        return res.json()
        }
      }
      )
      .then((adllDoctors) => {
        if(adllDoctors.token){
          authContext.adminLogin({}, adllDoctors.token);
        }
        
        console.log("adllDoctors", adllDoctors);
        setUsers(adllDoctors.data.docs);
        setTotal(adllDoctors.data.total);
      });
  }, [params.page, gender, searchinput]);
  const handleDelete = (id) => {
    deleteDoctorApi(id);
  };
  return (
    <>
      <div
        id="content "
        className="bg-cyan-100  h-svh  overflow-scroll -z-20 w-full"
      >

        <div class="container  mx-auto ">
          <div className="topbar">
            <div className="topbar-content grid grid-cols-12 md:grid-cols-12 gap-4  md:gap-5    items-center p-10 lg:text-xl text-sm text-center">
              <div className=" topbar-doctor-info col-span-2  flex justify-center items-center  gap-5 ">
                <div className="hidden md:block info-name text-center"></div>
              </div>
              <div className=" md:hidden   block  topbar-icon col-span-1"></div>
              <div className=" h-fit topbar-search  w-full  p-3  col-span-7    transition-all overflow-hidden cursor-pointer   ">
                <div
                  dir="ltr"
                  className=" rounded-xl   border-[1px] border-solid border-emerald-800  bg-emerald-200 flex  overflow-x-hidden "
                >
                  <button
                    onClick={handleSearchSubmit}
                    type="submit"
                    className="  bg-emerald-700 hover:bg-emerald-900"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <input
                    className="p-2  bg-emerald-200  w-[94%]    "
                    type="text"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
              <div className=" col-span-2 md:col-span-1"></div>
              <div className=" md:block   hidden  topbar-icon col-span-2 md:col-span-1">
                <Logo />
              </div>
            </div>
          </div>

          <div class="seven w-3/5 mx-auto my-5  md:text-5xl text-2xl">
            <h1> List of doctors</h1>
          </div>
          <div class="   h-full rounded-3xl home-content-users-table  home-content-users-title text-left   justify-center">
            {
              <table class=" z-10 border rounded-3xl  w-4/5 mx-auto  border-collapse bg-white text-left  text-gray-500   border-dashed border-gray-500 ">
                <thead className=" w-full  bg-gray-50  ">

                  <tr className=" w-full grid grid-cols-12  items-center gap-1  sm:gap-3 px-6 py-4 font-bold lg:text-xl md:text-base  text-sm align-middle">
                    <th className="font-medium text-gray-900 col-span-1 py-4"></th>
                    <th className="font-medium text-gray-900 col-span-3 py-4">
                      Description
                    </th>
                    <th className="font-medium text-gray-900 col-span-2 py-4">
                      Password
                    </th>
                    <th className="font-medium text-gray-900 col-span-2 py-4">
                      Email
                    </th>
                    <th className="font-medium text-gray-900 col-span-2 py-4">
                      Fullname
                    </th>
                    <th className="font-medium text-gray-900 col-span-1  py-4">
                      ID
                    </th>
                    <th className="font-medium text-gray-900 col-span-1 py-4"></th>
                  </tr>
                </thead>
                <tbody className=" devide-x devide-dashed  w-full divide-y divide-gray-100 border-t border-gray-100">
                  {shownUsers.map((user) => (
                    <DoctorBox
                        user={user}
                        deleteDoctor={()=>{
                            handleDelete(user.id);
                        }}
                        setDescApi={(desc)=>{
                          setDescApi(desc,user.id);
                        }}
                    />
                  ))}
                </tbody>
              </table>
            }
          </div>
        </div>
        <Pagination
          items={users}
          total={total}
          itemsCount={limit}
          pathname="/p-admin/doctors"
          setShownUsers={setShownUsers}
        />
      </div>
      <div className=" hidden lg:block  absolute top-0 right-0 w-[100px] h-full bg-cyan-400 opacity-40 blur-[120px] rounded-full" />
      <div className=" hidden lg:block  absolute top-0 left-0 w-[100px] h-full bg-cyan-400 opacity-40 blur-[120px] rounded-full" />
    </>
  );
}
