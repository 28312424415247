import React, { useContext, useState } from "react";
import "./Login.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Input from "../../Components/Form/Input";
import Button from "../../Components/Form/Button";
import { useForm } from "../../hooks/useForm";
import swal from "sweetalert";
import {
  requiredValidator,
  minValidator,
  maxValidator,
  emailValidator,
} from "../../validators/rules";
import { useEffect } from "react";
import AuthContext from "../../context/authContext";
import { Base_url, loginDoctor, signIn } from "../../Config/api/api";
import Logo from "../../Components/Logo/Logo";

export default function Login() {
  const [reCaptchaVerify, setReCaptchaVerify] = useState(false);


  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const [formState, onInputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: true,
      },
    },
    false
  );

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("doctor"))) {
      navigate("/p-doctor/1");
    }
  }, []);

  const userLogin = (event) => {
    event.preventDefault();

    const userData = {
      password: formState.inputs.password.value,
      email: formState.inputs.email.value,
    };

    if (formState.inputs.email.isValid) {
      fetch(Base_url + loginDoctor + "?email=" + formState.inputs.email.value, {
        method: "POST",
        body: JSON.stringify(userData),
      })
        .then((res) => {
          if (!res.ok) {
            return res.text().then((text) => {
              throw new Error(text);
            });
          } else {
            return res.json();
          }
        })
        .then((result) => {
          if(result.token){
            authContext.doctorLogin({}, result.token);
          }
          if (result.statusCode === 401) {
            localStorage.setItem("token", "");
            navigate("/", { replace: true });
            return;
          }
          if (result.status) {
            swal({
              title: "Login successful",
              icon: "success",
              buttons: "Login to the user panel",
            }).then((value) => {
              navigate("/p-doctor/1");
            });
            authContext.doctorLogin({}, result.token);
          } else {
            swal({
              title: "This user does not exist",
              icon: "error",
              buttons: "try again",
            });
          }
        })
        .catch((err) => {
          swal({
            title: "Error connecting to the server",
            icon: "error",
            buttons: "try again",
          });
        });
    } else {
      swal({
        title: "Please enter a correct email",
        icon: "error",
        buttons: "try again",
      });
    }
  };
  const [passType, setPassType] = useState("password");
  const myFunction = () => {
    if (passType == "password") {
      setPassType("text");
    } else {
      setPassType("password");
    }
  };
  return (
    <>
      <main
        dir="ltr"
        className="relative bg-slate-300 w-full h-[100vh] overflow-scroll md:overflow-auto"
      >
        <section className="login-register ">
          <div class="login shadow-2xl ">
            <a className=" items-center mb-5" href="#" style={{}}>
              <Logo />
            </a>
            <span class="login__title"></span>
            <span class="login__subtitle">
              Hello and welcome to
              <span className=" font-bold  text-teal-600  "> Skinmind's </span>
              web app
            </span>

            <form action="#" className="login-form inline w-4/5   ">
              <div class="login-form__username">
                <Input
                  className="login-form__username-input"
                  id="email"
                  type="text"
                  placeholder="email address"
                  element="input"
                  validations={[requiredValidator(), emailValidator()]}
                  onInputHandler={onInputHandler}
                />
              </div>
              <div class="login-form__password ">
                <Input
                  className="login-form__password-input "
                  id="password"
                  element="input"
                  type={passType}
                  placeholder="password"
                  onInputHandler={onInputHandler}
                  onChangeHandler={(event)=>{
                    console.log("kos");
                  }}
                  validations={[requiredValidator()]}
                />

                <div
                  onClick={() => myFunction()}
                  className={`${passType === "password" ? " block" : " hidden"}`}
                >
                  <i
                    className={` cursor-pointer login-form__password-icon fa fa-eye-slash  text-teal-400`}
                  ></i>
                </div>
                <div
                  onClick={() => myFunction()}
                  className={`${passType == "password" ? " hidden" : " block"}`}
                >
                  <i
                    className={` cursor-pointer  login-form__password-icon fa fa-eye text-teal-400`}
                  ></i>
                </div>
              </div>
              <Button
                className={`login-form__btn  sm:w-3/5 w-4/5 mx-auto self-center ${"login-form__btn-selected"}`}
                type="submit"
                onClick={userLogin}
              >
                <i class="login-form__btn-icon fas fa-sign-out-alt"></i>
                <span class="login-form__btn-text">Enter</span>
              </Button>
            </form>
            {/*<div class="login__des">*/}
            {/*  <span class="login__des-title ">Hello, dear user:</span>*/}
            {/*  <ul class="login__des-list">*/}
            {/*    <li class="login__des-item">*/}
            {/*      Please use reliable and up-to-date browsers such as Google*/}
            {/*      Chrome and Firefox use.*/}
            {/*    </li>*/}
            {/*    <li class="login__des-item">*/}
            {/*      We never ask for your confidential information via email.*/}
            {/*    </li>*/}
            {/*    <li class="login__des-item text-">*/}
            {/*      Please change your password at short intervals.*/}
            {/*    </li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
          </div>
        </section>

        <div className=" hidden lg:block  absolute top-0 left-0 w-[300px] h-[300px] bg-emerald-400 opacity-40 blur-[120px] rounded-full" />
        <div className="hidden lg:block  absolute bottom-0 right-0 w-[300px] h-[300px] bg-emerald-400  opacity-40 blur-[120px] rounded-full" />

        <div
          class=""
          className=" hidden lg:block  absolute bottom-0 left-0 w-[300px] h-[300px] bg-teal-500 opacity-40 blur-[120px] rounded-full"
        />
        <div className=" hidden lg:block  absolute top-0 right-0 w-[300px] h-[300px] bg-teal-500 opacity-40 blur-[120px] rounded-full" />
      </main>
    </>
  );
}
