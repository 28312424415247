import React from "react";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import Logo from "../../Components/Logo/Logo";
import { useContext } from "react";
import AuthContext from "../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../Components/Pagination/Pagination";
import {
  requiredValidator,
  minValidator,
  maxValidator,
  emailValidator,
} from "../../validators/rules";
import { Base_url } from "../../Config/api/api";
import UserBox from "../../Components/UserList/UserList";
import "./DoctorPanel.css";
import DoctorTopbar from "../../Components/DoctorTopbar/DoctorTopbar";
export default function DoctorPanel() {
  const [shownUsers, setShownUsers] = useState([]);
  const [total, setTotal] = useState(9);
  const [limit, setLimit] = useState(6);
  const [users, setUsers] = useState([]);
  const [gender, setGender] = useState("");
  const [page, setPage] = useState(1);
  const [searchinput, setSearchInput] = useState("");
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const params = useParams();
  const [doctorProfile, setDoctorProfile] = useState([]);
  const [isImgShow, setIsImgShow] = useState(false);
  const onImageLoaded = () => setIsImgShow(true);
  const genderHandler = async (event) => {
    let selectedGender = event.target.innerText;
    navigate("/p-doctor/1");
    if (selectedGender === "both") {
      setGender("");
    } else {
      setGender(selectedGender);
    }

    // Call genderRequest with the updated gender value
  };
  const handleSearchChange = (event) => {
    console.log(event.target);
    setSearchValue(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    setSearchInput(searchValue);
  };
  const signOut = (event) => {
    event.preventDefault();
    swal({
      title: "sign out successfully",
      icon: "success",
      buttons: "confirm",
    }).then(() => {
      authContext.doctorLogout();
      navigate("/d-login");
    });
  };

  useEffect(() => {
    const localStorageData = JSON.parse(localStorage.getItem("doctor"));
    console.log("localStorageData", localStorageData);

    fetch(Base_url + "v2/getProfile.php?isDoctor=1", {
      headers: {
        Authorization: `Bearer ${localStorageData.token}`,
      },
    })
      .then((res) =>{
        console.log(res ,"res");
        if (res.status == 401) {
          localStorage.setItem("doctor",null);
          navigate("/");
          return;
        }else{
        return res.json()
        }
      }
      )
      .then((dProf) => {
        if(dProf.token){
          authContext.doctorLogin({}, dProf.token);
        }
        if (dProf.statusCode === 401) {
          localStorage.setItem("doctor", "");
          navigate("/", { replace: true });
          return;
        }
        console.log(dProf);
        localStorage.setItem("doctorProfile",JSON.stringify(dProf.data));
        setDoctorProfile(dProf.data);
        console.log(doctorProfile);
      });

    fetch(
      Base_url +
        `v2/getMyPatients.php?limit=${limit}&page=${params.page}&gender=${gender}&search=${searchinput}`,
      {
        headers: {
          Authorization: `Bearer ${localStorageData.token}`,
        },
      }
    )
      .then((res) => {
        if (res.status == 401) {
          localStorage.setItem("doctor",null);
          navigate("/");
          return;
        }else{
        return res.json()
        }
      }
      )
      .then((allUsers) => {
        if(allUsers.token){
          authContext.doctorLogin({}, allUsers.token);
        }
        
        console.log(allUsers);
        
        console.log(allUsers);
        setUsers(allUsers.data.docs);
        setTotal(allUsers.data.total);
      });
  }, [params.page, gender, searchinput]);

  return (
    <>
      <div id="content " className="bg-cyan-100  h-svh  overflow-scroll -z-20">
        <div class="container  mx-auto ">
          <div className="topbar">
            <div className="topbar-content grid grid-cols-12 md:grid-cols-12 gap-4  md:gap-5    items-center p-10 lg:text-xl text-sm text-center">
              <div
                onClick={signOut}
                className=" topbar-signout p-3 col-span-2 sm:col-span-1 transition-all overflow-hidden cursor-pointer text-center rounded-full sm:rounded-3xl hover:bg-neutral-200 hover:border-neutral-500  border border-solid hover:text-neutral-500  text-white bg-neutral-500"
              >
                {" "}
                <i class="fa fa-sign-out" aria-hidden="true"></i>
                <span className="hidden md:inline-block px-2">
                  Sign Out
                </span>{" "}
              </div>

              <div className=" topbar-doctor-info col-span-2  flex justify-center items-center  gap-5  hover:cursor-pointer  " onClick={()=>navigate("/editProf")}>
                <div className="hidden md:block info-name text-center">
                  {doctorProfile.fullname}
                </div>
                <div className="info-image ">
                  <img
                    alt="doctor Image"
                    src={`${
                      doctorProfile.image != ""
                        ? doctorProfile.image
                        : "https://th.bing.com/th/id/OIP.UY0H6jNLhhjKymJWT6HsPwHaHa?rs=1&pid=ImgDetMain"
                    }`}
                    className=" w-20 h-20 object-cover rounded-full"
                  />
                </div>
              </div>
              <div className=" md:hidden   block  topbar-icon col-span-1"></div>
              <div className=" h-fit topbar-search  w-full  p-3  col-span-7    transition-all overflow-hidden cursor-pointer   ">
                <div
                  dir="ltr"
                  className=" rounded-xl   border-[1px] border-solid border-emerald-800  bg-emerald-200 flex  overflow-x-hidden "
                >
                  <button
                    onClick={handleSearchSubmit}
                    type="submit"
                    className="  bg-emerald-700 hover:bg-emerald-900"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <input
                    className="p-2  bg-emerald-200  w-[94%]    "
                    type="text"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
              <div className=" col-span-2 md:col-span-1"></div>
              <div className=" md:block   hidden  topbar-icon col-span-2 md:col-span-1">
                <Logo />
              </div>
            </div>
          </div>
          <div
            style={{ display: "none" }}
            className="flex items-center justify-end w-4/5 gap-4   "
          >
            <div
              dir="ltr"
              className={`  hover:border-slate-400 gender-select  hover:border-sla  
                cursor-pointer hover:bg-slate-200  
                rounded-lg p-3   border border-solid w-max
                ${
                  gender == ""
                    ? "bg-slate-300 border-slate-500 "
                    : "bg-slate-100 border-slate-300 "
                }
                `}
              onClick={genderHandler}
            >
              both{" "}
            </div>

            <div
              dir="ltr"
              className={` hover:border-slate-400 gender-select  hover:border-sla  
                cursor-pointer hover:bg-slate-200  
                rounded-lg p-3   border border-solid w-max
                ${
                  gender == "male"
                    ? "bg-slate-300 border-slate-500 "
                    : "bg-slate-100 border-slate-300 "
                }
                `}
              onClick={genderHandler}
            >
              male{" "}
            </div>
            <div
              dir="ltr"
              className={` hover:border-slate-400 gender-select  hover:border-sla  
                cursor-pointer hover:bg-slate-200  
                rounded-lg p-3   border border-solid w-max
                ${
                  gender == "female"
                    ? "bg-slate-300 border-slate-500 "
                    : "bg-slate-100 border-slate-300 "
                }
                `}
              onClick={genderHandler}
            >
              female
            </div>
          </div>
          <div class="seven w-3/5 mx-auto my-5  md:text-5xl text-2xl">
            <h1> List of patients</h1>
          </div>
          <div class="   h-full rounded-3xl home-content-users-table  home-content-users-title text-left   justify-center">
            {
              <table class=" z-10 border rounded-3xl  w-4/5 mx-auto  border-collapse bg-white text-left  text-gray-500   border-dashed border-gray-500 ">
                <thead className=" w-full  bg-gray-50  ">
                  <tr className=" w-full grid lg:grid-cols-9 grid-cols-10  items-center gap-1  sm:gap-3 px-6 py-4 font-bold lg:text-xl md:text-base  text-sm align-middle">
                    <th className="font-medium text-gray-900 col-span-2  py-4">
                      BirthDay
                    </th>
                    <th className="font-medium text-gray-900 col-span-2 py-4">
                      Gender
                    </th>
                    <th className="font-medium text-gray-900 col-span-2  py-4">
                      Lastname
                    </th>
                    <th className="font-medium text-gray-900 col-span-2  py-4">
                      Firstname{" "}
                    </th>

                    <th className="font-medium text-gray-900 lg:col-span-1 col-span-2 py-4"></th>
                  </tr>
                </thead>
                <tbody className=" devide-x devide-dashed  w-full divide-y divide-gray-100 border-t border-gray-100">
                  {shownUsers.map((user) => (
                    <UserBox {...user} />
                  ))}
                </tbody>
              </table>
            }
          </div>
        </div>
        <Pagination
          items={users}
          total={total}
          itemsCount={limit}
          pathname="/p-doctor"
          setShownUsers={setShownUsers}
        />
      </div>
      <div className=" hidden lg:block  absolute top-0 right-0 w-[100px] h-full bg-cyan-400 opacity-40 blur-[120px] rounded-full" />
      <div className=" hidden lg:block  absolute top-0 left-0 w-[100px] h-full bg-cyan-400 opacity-40 blur-[120px] rounded-full" />
    </>
  );
}
